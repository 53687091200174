import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import ShowMessage from "../../components/ShowMessage/ShowMessage";
import { db } from "../../firebase";
import { useUserAuth } from "../../UserAuthContext";
const storage = getStorage();
const bucketName = "textspeech-55a09.appspot.com";

export default function ClipsMediaUploader(props) {
  const { user } = useUserAuth();
  const fileTypes = ["mp4"];
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // Handles multiple file uploads in parallel
  // only supported for media files for now.
  async function processFiles(file) {
    props.setProcessing(true);
    const gsUrl = await handleFileUpload(file);
    if (!gsUrl) {
      props.setProcessing(false);
      return;
    }
    props.setUploadedFile({ fileName: file.name, gsUrl: gsUrl });
    props.setProcessing(false);
  }

  const handleFileUpload = async (file) => {
    const fname = file.name;
    const uid = user.uid;

    const mediaUrl = `${uid}/clipvids/${fname}`;
    const storageRef = ref(storage, mediaUrl);
    const mediaGsUrl = `gs://${bucketName}/${mediaUrl}`;
    console.log(mediaGsUrl);

    if (file.type.startsWith("video/")) {
      // console.log(storageRef);
      const videoDuration = await getVideoDuration(file);
      if (videoDuration < 30) {
        handleShowMessage(
          "Video is too short for AI to generate interesting clips"
        );
        return;
      }

      if (videoDuration > 3000) {
        handleShowMessage("The maximum video length supported is 1 hour");
        return;
      }

      await uploadBytes(storageRef, file);
      return mediaGsUrl;
    } else {
      alert("Please upload a video file.");
    }
    return fname;
  };

  const handleShowMessage = (message) => {
    let err = message ? message : "File size exceeds maximum limit (500MB)";
    setShowError(true);
    setErrorMessage(err);
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        // Clean up the object URL and resolve with the duration
        URL.revokeObjectURL(video.src);
        console.log(video.duration);
        resolve(video.duration);
      };

      video.onerror = () => {
        // Clean up the object URL and reject the promise
        URL.revokeObjectURL(video.src);
        reject("Failed to load video metadata");
      };

      // Create a blob URL for the video file
      const fileURL = URL.createObjectURL(file);
      video.src = fileURL;
    });
  };

  return (
    <FileUploader
      handleChange={processFiles}
      multiple={false}
      name="file"
      types={fileTypes}
      maxSize={500}
      onSizeError={handleShowMessage}
    >
      <div className="cursor-pointer rounded-lg bg-black border border-dashed border-gray-300 text-center p-4 bg-gray-900 transition duration-300 ease-in-out hover:border-primary hover:hover:bg-primary/10 w-full">
        <div className="flex items-center justify-center">
          <span className="text-xs text-brownMedium justify-center flex items-center justify-center">
            <CloudUploadOutlinedIcon className="text-primary  mr-2" />
            Click to browse or drag & drop files
          </span>
        </div>
      </div>
      <ShowMessage
        showFileError={showError}
        setShowFileError={setShowError}
        severity="error"
        message={errorMessage}
      />
    </FileUploader>
  );
}
