import React from "react";
import { cdnGen } from "../libs/fileOps";
import { deepFindVideos } from "../canvas/utils/canvasUtils";

export const START_TRANSITION_INDEX = 0;
export const MIDDLE_TRANSITION_INDEX = 1;
export const END_TRANSITION_INDEX = 2;
export const transitionPositions = {
  [START_TRANSITION_INDEX]: "START",
  [MIDDLE_TRANSITION_INDEX]: "MIDDLE",
  [END_TRANSITION_INDEX]: "END",
};
export const transitionIndex = {
  START: START_TRANSITION_INDEX,
  MIDDLE: MIDDLE_TRANSITION_INDEX,
  END: END_TRANSITION_INDEX,
};

export function reCalculateTransitionTime(object) {
  let startTime = object.animationStartTime;
  (object.transitions || []).forEach((transition) => {
    transition.startTime = startTime;
    transition.endTime = startTime + transition.duration;
    if (transition.endTime === transition.startTime)
      transition.endTime += 0.00001;

    startTime = transition.endTime + 0.00001;
  });
}

export const START_TRANSITIONS = [
  {
    id: 11,
    title: "None",
    type: "Static",
    iconName: "static.svg",
  },
  {
    id: 21,
    title: "Fade",
    type: "fade in",
    iconName: "fade-in.svg",
  },
  {
    id: 691,
    title: "Float",
    type: "float",
    iconName: "float-in.svg",
    direction: "left",
    directionOptions: ["left", "right", "top", "bottom"],
  },
  {
    id: 611,
    title: "Zoom In",
    type: "zoom in",
    iconName: "zoom-in.svg",
  },
  {
    id: 441,
    title: "Scale",
    type: "drop",
    iconName: "scale-in.svg",
  },
  {
    id: 61,
    title: "Slide",
    type: "slide",
    iconName: "slide-in.svg",
    direction: "left",
    directionOptions: ["left", "right", "top", "bottom"],
  },
  {
    id: 616,
    title: "Pop",
    type: "pop",
    iconName: "pop.svg",
  },

  {
    id: 8921,
    title: "Elastic Slide",
    type: "slide bounce",
    iconName: "slide-bounce.svg",
    direction: "left",
    directionOptions: ["left", "right", "top", "bottom"],
  },
  {
    id: 7671,
    title: "Bounce",
    type: "bounce",
    iconName: "bounce.svg",
  },
  {
    id: 761,
    title: "Spin",
    type: "spin",
    iconName: "spin.svg",
  },
  {
    id: 891,
    title: "Tumble",
    type: "gentle float",
    direction: "left",
    directionOptions: ["left", "right"],
    iconName: "tumble-in.svg",
  },

  {
    id: 391,
    title: "Breathe",
    type: "breath",
    iconName: "breath.svg",
  },

  {
    id: 881,
    title: "Pulse",
    type: "pulse",
    iconName: "pulse.svg",
  },
  {
    id: 261,
    title: "Flicker",
    type: "flicker",
    iconName: "flicker.svg",
  },
  /* {
        id: 969,
        title: 'Scrapbook',
        type: 'scrapebook',
        iconName: 'scrapbook.svg',
        direction: 'Left - Top',
        directionOptions: ['LT', 'RT', 'LB', 'RB'],

    },*/
];
export const END_TRANSITIONS = [
  {
    id: 11,
    title: "None",
    type: "Static",
    iconName: "static.svg",
  },

  {
    id: 22,
    title: "Fade",
    type: "fade out",
    iconName: "fade-out.svg",
  },

  /*{
    id: 691,
    title: "Float",
    type: "float-out",
    iconName: "float-in.svg",
    direction: "left",
    directionOptions: ["left", "right", "top", "bottom"],
  },*/
  {
    id: 602,
    title: "Zoom Out",
    type: "zoom out",
    iconName: "zoom-out.svg",
  },

  {
    id: 441,
    title: "Scale",
    type: "drop-out",
    iconName: "scale-out.svg",
  },
  {
    id: 62,
    title: "Slide",
    type: "fly out",
    iconName: "slide-in.svg",
    direction: "right",
    directionOptions: ["left", "right", "top", "bottom"],
  },

  {
    id: 616,
    title: "Pop",
    type: "pop-out",
    iconName: "pop.svg",
  },
  {
    id: 6126,
    title: "Bounce",
    type: "bounce-out",
    iconName: "bounce.svg",
  },

  {
    id: 4412,
    title: "Tumble",
    type: "Tumble out",
    direction: "left",
    directionOptions: ["left", "right"],
    iconName: "tumble-in.svg",
  },
];
export const EASING_FUNCTIONS = [
  { title: "Linear", value: "linear" },
  { title: "Ease In Quad", value: "easeInQuad" },
  { title: "Ease Out Quad", value: "easeOutQuad" },
  { title: "Ease In Out Quad", value: "easeInOutQuad" },
  { title: "Ease In Cubic", value: "easeInCubic" },
  { title: "Ease Out Cubic", value: "easeOutCubic" },
  { title: "Ease In Out Cubic", value: "easeInOutCubic" },
  { title: "Ease In Quart", value: "easeInQuart" },
  { title: "Ease Out Quart", value: "easeOutQuart" },
  { title: "Ease In Out Quart", value: "easeInOutQuart" },
  { title: "Ease Out Bounce", value: "easeOutBounce" },
  { title: "Swing From", value: "swingFrom" },
  { title: "Swing To", value: "swingTo" },
  { title: "Spring", value: "spring", custom: [1, 100, 13, 0] },
  { title: "Elastic", value: "elastic" },
];

export function getDefaultEasing(transition) {
  let easing = null;
  switch (transition.type.toLowerCase()) {
    case "slide":
    case "zoom in":
    case "gentle float":
    case "tumble out":
    case "float":
    case "float-out":
    case "breath":
    case "drop":
    case "drop-out":
    case "pulse":
      easing = EASING_FUNCTIONS.find((e) => e.value === "easeOutQuart");
      break;
    case "pop":
    case "bounce":
      easing = EASING_FUNCTIONS.find((e) => e.value === "swingTo");
      break;
    case "pop-out":
    case "bounce-out":
      easing = EASING_FUNCTIONS.find((e) => e.value === "swingFrom");
      break;
    case "slide bounce":
      easing = EASING_FUNCTIONS.find((e) => e.value === "easeOutBounce");
      break;
    case "fly out":
    case "zoom out":
      easing = EASING_FUNCTIONS.find((e) => e.value === "easeInQuart");
      break;
  }

  if (!easing) easing = EASING_FUNCTIONS.find((e) => e.value === "linear");

  if (easing.custom) easing.value = easing.custom;

  return easing;
}

const getScrapebookTLKeyframes = (
  objectLeft,
  objectTop,
  frameTop,
  frameLeft,
  objectAngle,
  startTime,
  endTime,
  actor,
  transition
) => {
  let transitionDuration = endTime - startTime;
  const intervalDuration = transitionDuration / 4,
    quarterDistanceTop = (objectTop - frameTop) / 4,
    quarterDistanceLeft = (objectLeft - frameLeft) / 4;

  let keyframes = [
    {
      top: objectTop - 3 * quarterDistanceTop,
      left: objectLeft - 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 0,
    },
    {
      top: objectTop - 3 * quarterDistanceTop,
      left: objectLeft - 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 1,
    },
    {
      top: objectTop - 2 * quarterDistanceTop,
      left: objectLeft - 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 1 + 0.001,
    },
    {
      top: objectTop - 2 * quarterDistanceTop,
      left: objectLeft - 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 2,
    },
    {
      top: objectTop - quarterDistanceTop,
      left: objectLeft - quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 2 + 0.001,
    },
    {
      top: objectTop - quarterDistanceTop,
      left: objectLeft - quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 3,
    },
    {
      top: objectTop,
      left: objectLeft,
      angle: objectAngle,
      startTime: startTime + intervalDuration * 3 + 0.001,
    },
    {
      top: objectTop,
      left: objectLeft,
      angle: objectAngle,
      startTime: startTime + intervalDuration * 4,
    },
  ];

  keyframes.forEach((keyFrame) => {
    let frame = { ...keyFrame };
    delete frame.startTime;
    actor.keyframe(keyFrame.startTime, frame, transition.easing.value);
  });
};
const getScrapebookTRKeyframes = (
  objectLeft,
  objectTop,
  frameTop,
  frameLeft,
  objectAngle,
  startTime,
  endTime,
  actor,
  transition
) => {
  let transitionDuration = endTime - startTime;

  const intervalDuration = transitionDuration / 4,
    quarterDistanceTop = (objectTop - frameTop) / 4,
    quarterDistanceLeft = (frameLeft - objectLeft) / 4;
  let keyframes = [
    {
      top: objectTop - 3 * quarterDistanceTop,
      left: objectLeft + 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 0,
    },
    {
      top: objectTop - 3 * quarterDistanceTop,
      left: objectLeft + 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 1,
    },
    {
      top: objectTop - 2 * quarterDistanceTop,
      left: objectLeft + 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 1 + 0.001,
    },
    {
      top: objectTop - 2 * quarterDistanceTop,
      left: objectLeft + 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 2,
    },
    {
      top: objectTop - quarterDistanceTop,
      left: objectLeft + quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 2 + 0.001,
    },
    {
      top: objectTop - quarterDistanceTop,
      left: objectLeft + quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 3,
    },
    {
      top: objectTop,
      left: objectLeft,
      angle: objectAngle,
      startTime: startTime + intervalDuration * 3 + 0.001,
    },
  ];
  keyframes.forEach((keyFrame) => {
    let frame = { ...keyFrame };
    delete frame.startTime;
    actor.keyframe(keyFrame.startTime, frame, transition.easing.value);
  });
};

const getScrapebookBLKeyframes = (
  objectLeft,
  objectTop,
  frameTop,
  frameLeft,
  objectAngle,
  startTime,
  endTime,
  actor,
  transition
) => {
  let transitionDuration = endTime - startTime;

  const intervalDuration = transitionDuration / 4,
    quarterDistanceTop = (frameTop - objectTop) / 4,
    quarterDistanceLeft = (objectLeft - frameLeft) / 4;
  let keyframes = [
    {
      top: objectTop + 3 * quarterDistanceTop,
      left: objectLeft - 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 0,
    },
    {
      top: objectTop + 3 * quarterDistanceTop,
      left: objectLeft - 3 * quarterDistanceLeft,
      angle: objectAngle + 20,
      startTime: startTime + intervalDuration * 1,
    },
    {
      top: objectTop + 2 * quarterDistanceTop,
      left: objectLeft - 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 1 + 0.001,
    },
    {
      top: objectTop + 2 * quarterDistanceTop,
      left: objectLeft - 2 * quarterDistanceLeft,
      angle: objectAngle - 25,
      startTime: startTime + intervalDuration * 2,
    },
    {
      top: objectTop + quarterDistanceTop,
      left: objectLeft - quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 2 + 0.001,
    },
    {
      top: objectTop + quarterDistanceTop,
      left: objectLeft - quarterDistanceLeft,
      angle: objectAngle + 23,
      startTime: startTime + intervalDuration * 3,
    },
    {
      top: objectTop,
      left: objectLeft,
      angle: objectAngle,
      startTime: startTime + intervalDuration * 3 + 0.001,
    },
  ];
  keyframes.forEach((keyFrame) => {
    let frame = { ...keyFrame };
    delete frame.startTime;
    actor.keyframe(keyFrame.startTime, frame, transition.easing.value);
  });
};

const getScrapebookBRKeyframes = (
  objectLeft,
  objectTop,
  frameTop,
  frameLeft,
  angle,
  startTime,
  endTime,
  actor,
  transition
) => {
  let transitionDuration = endTime - startTime;
  const intervalDuration = transitionDuration / 4,
    l = (frameTop - objectTop) / 4,
    s = (frameLeft - objectLeft) / 4;
  const keyframes = [
    {
      top: objectTop + 3 * l,
      left: objectLeft + 3 * s,
      angle: angle + 20,
      startTime: startTime + intervalDuration * 0,
    },
    {
      top: objectTop + 3 * l,
      left: objectLeft + 3 * s,
      angle: angle + 20,
      startTime: startTime + intervalDuration * 1,
    },
    {
      top: objectTop + 2 * l,
      left: objectLeft + 2 * s,
      angle: angle - 25,
      startTime: startTime + intervalDuration * 1 + 0.001,
    },
    {
      top: objectTop + 2 * l,
      left: objectLeft + 2 * s,
      angle: angle - 25,
      startTime: startTime + intervalDuration * 2,
    },
    {
      top: objectTop + l,
      left: objectLeft + s,
      angle: angle + 23,
      startTime: startTime + intervalDuration * 2 + 0.001,
    },
    {
      top: objectTop + l,
      left: objectLeft + s,
      angle: angle + 23,
      startTime: startTime + intervalDuration * 3,
    },
    {
      top: objectTop,
      left: objectLeft,
      angle: angle,
      startTime: startTime + intervalDuration * 3 + 0.001,
    },
  ];
  keyframes.forEach((keyFrame) => {
    let frame = { ...keyFrame };
    delete frame.startTime;
    actor.keyframe(keyFrame.startTime, frame, transition.easing.value);
  });
};
export function convertTransitionToKeyframes(actor, transition, object) {
  try {
    let startTime = transition.startTime * 1000; //in ms
    let endTime = transition.endTime * 1000; //in ms
    transition.easing = transition.easing || getDefaultEasing(transition);

    switch (transition.type.toLowerCase()) {
      case "slide bounce":
      case "slide": {
        let startProps = {};
        let endProps = {};
        switch (transition.direction) {
          case "top": {
            let from =
              object.__parentArtboard.top - object.getScaledHeight() / 2;
            let to = object.top;
            startProps = { top: from, opacity: 0 };
            endProps = { top: to, opacity: object.opacity };
            break;
          }
          case "right": {
            let from =
              object.__parentArtboard.getPointByOrigin("right", "center").x +
              object.getScaledWidth() / 2;
            let to = object.left;
            startProps = { left: from, opacity: 0 };
            endProps = { left: to, opacity: object.opacity };
            break;
          }

          case "bottom": {
            let from =
              object.__parentArtboard.getPointByOrigin("center", "bottom").y +
              object.getScaledHeight() / 2;
            let to = object.top;
            startProps = { top: from, opacity: 0 };
            endProps = { top: to, opacity: object.opacity };
            break;
          }

          default: {
            let from =
              object.__parentArtboard.left - object.getScaledWidth() / 2;
            let to = object.left;
            startProps = { left: from, opacity: 0 };
            endProps = { left: to, opacity: object.opacity };
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "bounce": {
        let startProps = {};
        let endProps = {};

        let from = object.getPointByOrigin("center", "bottom").y;
        let to = object.top;
        startProps = { top: from };
        endProps = { top: to };

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);

        break;
      }
      case "bounce-out": {
        let startProps = {};
        let endProps = {};

        let to = object.getPointByOrigin("center", "bottom").y;
        let from = object.top;
        startProps = { top: from, opacity: object.opacity };
        endProps = { top: to, opacity: 0 };

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);

        break;
      }
      case "fly out": {
        let startProps = {};
        let endProps = {};
        switch (transition.direction) {
          case "top": {
            let to = object.top - object.__parentArtboard.height;
            let from = object.top;
            startProps = { top: from, opacity: object.opacity };
            endProps = { top: to, opacity: 0 };
            break;
          }
          case "right": {
            let to = object.left + object.__parentArtboard.width;
            let from = object.left;
            startProps = { left: from, opacity: object.opacity };
            endProps = { left: to, opacity: 0 };
            break;
          }

          case "bottom": {
            let to = object.top + object.__parentArtboard.height;
            let from = object.top;
            startProps = { top: from, opacity: object.opacity };
            endProps = { top: to, opacity: 0 };
            break;
          }

          default: {
            let to = object.left - object.__parentArtboard.width;
            let from = object.left;
            startProps = { left: from, opacity: object.opacity };
            endProps = { left: to, opacity: 0 };
            break;
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "fade in": {
        let from = 0;
        let to = object.opacity;

        actor
          .keyframe(startTime, { opacity: from }, transition.easing.value)
          .keyframe(endTime, { opacity: to }, transition.easing.value);
        break;
      }
      case "fade out": {
        let from = object.opacity;
        let to = 0;

        actor
          .keyframe(startTime, { opacity: from }, transition.easing.value)
          .keyframe(endTime, { opacity: to }, transition.easing.value);
        break;
      }

      case "pop":
      case "zoom in": {
        let toY = object.scaleY;
        let fromY = 0;

        let toX = object.scaleX;
        let fromX = 0;
        actor
          .keyframe(
            startTime,
            {
              scaleY: fromY,
              scaleX: fromX,
              originY: 0.5,
              originX: 0.5,
            },
            transition.easing.value
          )
          .keyframe(
            endTime,
            { scaleY: toY, scaleX: toX, originY: 0.5, originX: 0.5 },
            transition.easing.value
          );

        break;
      }
      case "pop-out": {
        let fromY = object.scaleY;
        let toY = 0;

        let fromX = object.scaleX;
        let toX = 0;
        actor
          .keyframe(
            startTime,
            {
              scaleY: fromY,
              scaleX: fromX,
              originY: 0.5,
              originX: 0.5,
            },
            transition.easing.value
          )
          .keyframe(
            endTime,
            { scaleY: toY, scaleX: toX, originY: 0.5, originX: 0.5 },
            transition.easing.value
          );

        break;
      }
      case "zoom out": {
        let fromY = object.scaleY;
        let toY = 0;

        let fromX = object.scaleX;
        let toX = 0;
        actor
          .keyframe(
            startTime,
            {
              scaleY: fromY,
              scaleX: fromX,
              originY: 0.5,
              originX: 0.5,
            },
            transition.easing.value
          )
          .keyframe(
            endTime,
            { scaleY: toY, scaleX: toX, originY: 0.5, originX: 0.5 },
            transition.easing.value
          );

        break;
      }
      case "gentle float": {
        let startProps = {};
        let endProps = {};
        let startAngle = object.angle - 30;
        let endAngle = object.angle;
        switch (transition.direction) {
          case "right": {
            let from =
              object.__parentArtboard.getPointByOrigin("right", "center").x +
              object.getScaledWidth();
            let to = object.left;
            startProps = { left: from, angle: startAngle };
            endProps = { left: to, angle: endAngle };
            break;
          }
          case "left": {
            let from = object.__parentArtboard.left - object.getScaledWidth();
            let to = object.left;
            startProps = { left: from, angle: startAngle };
            endProps = { left: to, angle: endAngle };
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "tumble out": {
        let startProps = {};
        let endProps = {};
        let endAngle = object.angle + 30;
        let startAngle = object.angle;
        switch (transition.direction) {
          case "left": {
            let to =
              object.__parentArtboard.getPointByOrigin("right", "center").x +
              object.getScaledWidth();
            let from = object.left;
            startProps = { left: from, angle: startAngle };
            endProps = { left: to, angle: endAngle };
            break;
          }
          case "right": {
            let to = object.__parentArtboard.left - object.getScaledWidth();
            let from = object.left;
            startProps = { left: from, angle: startAngle };
            endProps = { left: to, angle: endAngle };
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "float": {
        let startProps = {};
        let endProps = {};
        switch (transition.direction) {
          case "top": {
            let from = object.top - 208;
            let to = object.top;
            startProps = { top: from, opacity: 0 };
            endProps = { top: to, opacity: object.opacity };
            break;
          }
          case "right": {
            let from = object.left + 208;
            let to = object.left;
            startProps = { left: from, opacity: 0 };
            endProps = { left: to, opacity: object.opacity };
            break;
          }

          case "bottom": {
            let from = object.top + 208;
            let to = object.top;
            startProps = { top: from, opacity: 0 };
            endProps = { top: to, opacity: object.opacity };
            break;
          }

          case "left": {
            let from = object.left - 208;
            let to = object.left;
            startProps = { left: from, opacity: 0 };
            endProps = { left: to, opacity: object.opacity };
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "float-out": {
        let startProps = {};
        let endProps = {};
        switch (transition.direction) {
          case "top": {
            let to = object.top - 208;
            let from = object.top;
            startProps = { top: from, opacity: object.opacity };
            endProps = { top: to, opacity: 0 };
            break;
          }
          case "right": {
            let to = object.left + 208;
            let from = object.left;
            startProps = { left: from, opacity: object.opacity };
            endProps = { left: to, opacity: 0 };
            break;
          }

          case "bottom": {
            let to = object.top + 208;
            let from = object.top;
            startProps = { top: from, opacity: object.opacity };
            endProps = { top: to, opacity: 0 };
            break;
          }

          case "left": {
            let to = object.left - 208;
            let from = object.left;
            startProps = { left: from, opacity: object.opacity };
            endProps = { left: to, opacity: 0 };
          }
        }

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "spin": {
        let from = object.angle - 360;
        let to = object.angle;
        let startProps = { angle: from };
        let endProps = { angle: to };
        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "breath": {
        let startProps = {
          scaleX: object.scaleX * 0.8,
          scaleY: object.scaleY * 0.8,
        };
        let endProps = { scaleX: object.scaleX, scaleY: object.scaleY };
        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "drop": {
        let startProps = {
          scaleX: object.scaleX * 3,
          scaleY: object.scaleY * 3,
          opacity: 0,
        };
        let endProps = {
          scaleX: object.scaleX,
          scaleY: object.scaleY,
          opacity: object.opacity,
        };
        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "drop-out": {
        let startProps = {
          scaleX: object.scaleX,
          scaleY: object.scaleY,
          opacity: object.opacity,
        };
        let endProps = {
          scaleX: object.scaleX * 1.5,
          scaleY: object.scaleY * 1.5,
          opacity: 0,
        };

        actor
          .keyframe(startTime, startProps, transition.easing.value)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "pulse": {
        let startProps = { opacity: 0 };
        let endProps = { opacity: object.opacity };
        let duration = endTime - startTime;
        let secondStart = startTime + duration / 2;

        actor
          .keyframe(startTime, startProps)
          .keyframe(secondStart, endProps, transition.easing.value)
          .keyframe(secondStart + 0.001, startProps)
          .keyframe(endTime, endProps, transition.easing.value);
        break;
      }
      case "flicker": {
        let startProps = { opacity: 0 };
        let endProps = { opacity: object.opacity };
        let duration = endTime - startTime;
        let interval = duration / 6;
        [1, 2, 3, 4, 5, 6].forEach(() => {
          actor
            .keyframe(startTime, startProps)
            .keyframe(startTime + interval, endProps, transition.easing.value);
          startTime += interval + 0.01;
        });
        break;
      }
      case "scrapebook": {
        switch (transition.direction) {
          case transition.directionOptions[0]:
            getScrapebookTLKeyframes(
              object.left,
              object.top,
              object.__parentArtboard.top,
              object.__parentArtboard.left,
              object.angle,
              startTime,
              endTime,
              actor,
              transition
            );
            break;
          case transition.directionOptions[1]:
            getScrapebookTRKeyframes(
              object.left,
              object.top,
              object.__parentArtboard.top,
              object.__parentArtboard.left + object.__parentArtboard.width,
              object.angle,
              startTime,
              endTime,
              actor,
              transition
            );
            break;
          case transition.directionOptions[2]:
            getScrapebookBLKeyframes(
              object.left,
              object.top,
              object.__parentArtboard.top + object.__parentArtboard.height,
              object.__parentArtboard.left,
              object.angle,
              startTime,
              endTime,
              actor,
              transition
            );
            break;
          case transition.directionOptions[3]:
            getScrapebookBRKeyframes(
              object.left,
              object.top,
              object.__parentArtboard.top + object.__parentArtboard.height,
              object.__parentArtboard.left + object.__parentArtboard.width,
              object.angle,
              startTime,
              endTime,
              actor,
              transition
            );
            break;
        }

        break;
      }
      case "static": {
        actor
          .keyframe(startTime, { left: object.left })
          .keyframe(endTime, { left: object.left });
        break;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function reInitKeyframes(object) {
  if (!object.actor) return;

  object.actor.resetObjectState(object);
  object.actor.removeAllKeyframes();
  object.transitions.forEach((transition) => {
    convertTransitionToKeyframes(object.actor, transition, object);
  });
}
export async function waitForVideoCanPlayThrough(videoElement) {
  return new Promise((resolve) => {
    const handler = () => {
      videoElement.removeEventListener("canplaythrough", handler);
      resolve();
    };

    videoElement.addEventListener("canplaythrough", handler);
  });
}
export async function waitForVideoSeeked(videoElement) {
  return new Promise((resolve) => {
    const handler = () => {
      resolve(true);
      videoElement.removeEventListener("seeked", handler);
    };
    videoElement.addEventListener("seeked", handler);
  });
}

export function hexToRGBA(h) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgba(" + +r + "," + +g + "," + +b + ", 1)";
}

export function getVideoElement(url, muted = true, loop = false) {
  return new Promise(async (resolve, reject) => {
    const videoEl = document.createElement("video");
    videoEl.style.display = "none";
    document.body.appendChild(videoEl);
    videoEl.autoPictureInPicture = false;
    videoEl.autoplay = false;
    videoEl.playsinline = true;
    videoEl.muted = muted;
    videoEl.loop = loop;
    videoEl.preload = "auto";
    videoEl.crossOrigin = "anonymous";

    const source = document.createElement("source");
    videoEl.appendChild(source);

    videoEl.onloadeddata = async function () {
      this.width = this.videoWidth;
      this.height = this.videoHeight;

      while (videoEl.duration === Infinity || isNaN(videoEl.duration)) {
        await new Promise((r) => setTimeout(r, 1000));
        videoEl.currentTime = 10000000 * Math.random();
      }

      videoEl.remove();
      resolve(videoEl);
    };

    videoEl.onerror = () => {
      reject();
    };

    source.src = cdnGen(url);
    videoEl.load();
  });
}
export async function handleRewindAllMedia({ children, _objects, canvas }) {
  let objects = children || _objects;

  await Promise.all(
    objects.map(async (child) => {
      if (child.type === "audio") {
        await child.stop();
        await child.seek(child.startTime);
      }
    })
  );

  await Promise.all(
    deepFindVideos({ children: objects }).map(async (video) => {
      await video.stopVideo();
      await video.seekVideo(video.startTime);
    })
  );

  canvas?.requestRenderAll();
}
export function handlePauseAllMedia(artboard) {
  artboard.children.map((child) => {
    if (child.type === "audio") {
      child.pause();
    }
  });

  deepFindVideos(artboard).map((video) => {
    video.pauseVideo();
  });
  artboard.canvas?.requestRenderAll();
}
export function shouldPlayMedia(child, currentTimeInMS) {
  //all times are in milliseconds
  let speed = child.speed || 1;
  let initialTimeInMS = (child.animationStartTime || 0) * 1000;
  let totalDurationInMS = Math.floor(
    ((child.endTime - child.startTime) / speed) * 1000
  );
  return (
    currentTimeInMS >= initialTimeInMS &&
    currentTimeInMS <= initialTimeInMS + totalDurationInMS
  );
}
export function generateVideoThumbnails(src, options = {}) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    video.autoplay = true;
    video.muted = true;
    video.src = src;
    video.crossOrigin = "anonymous";

    const frames = [];

    video.onloadeddata = async () => {
      let ctx = canvas.getContext("2d");
      let thumbnailDimension = {
        width: options.width || video.videoWidth,
        height: options.height || video.videoHeight,
      };

      canvas.width = thumbnailDimension.width;
      canvas.height = thumbnailDimension.height;

      video.pause();

      /**
       * For loop here!
       */
      // let tFrames=Math.ceil(video.duration/2);
      for (let i = 0.1; i < video.duration; i += 4) {
        video.currentTime = i;
        await waitForVideoSeeked(video);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const blob = await (await fetch(dataUrl)).blob();
        const blobUrl = URL.createObjectURL(blob);
        frames.push(blobUrl);
      }

      resolve(frames);
    };
  });
}
