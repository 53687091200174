import { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet-async";

import "@fontsource/roboto/400.css";

// import SsmlEditor from "../components/SsmlEditor/SsmlEditor"
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import ShowMessage from "../components/ShowMessage/ShowMessage";
import Iconify from "../components/iconify/Iconify";
import { useParams, Link as RouterLink } from "react-router-dom";

// @mui
import {
  Button,
  IconButton,
  Chip,
  Container,
  Grid,
  LinearProgress,
  Link,
  Radio,
  RadioGroup,
  Paper,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.js";

import { useUserAuth } from "../UserAuthContext";

// components

const VideoCard = ({ url }) => {
  const videoRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [hover, setHover] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current?.paused) {
      videoRef.current.play();
      setVideoPlaying(true);
    } else {
      videoRef.current.pause();
      setVideoPlaying(false);
    }
  };

  const handleDownload = (url) => {
    const fileName = "acoust.mp4";
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";

    // Handle the AJAX success event
    xhr.onload = function () {
      if (xhr.status === 200) {
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(xhr.response);
        link.download = fileName;

        // Simulate a click on the anchor element
        link.click();

        // Clean up the temporary URL object
        window.URL.revokeObjectURL(link.href);
      }
    };
    // Send the AJAX request
    xhr.send();
  };

  return (
    <Paper sx={{ maxWidth: "250px" }}>
      <Stack
        alignItems="center"
        padding={2}
        position="relative"
        onMouseEnter={() => setHover(true)} // Trigger hover state on mouse enter
        onMouseLeave={() => setHover(false)} // Reset hover state on mouse leave
      >
        <video
          ref={videoRef}
          src={url}
          style={{
            borderRadius: "8px",
            width: "100%", // Ensure the video scales with the container
            position: "relative",
          }}
        />
        {hover && (
          <IconButton
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: (theme) =>
                alpha(theme.palette.common.black, 0.5),
              zIndex: 1, // Make sure it appears above the video
            }}
            onClick={handlePlayPause}
          >
            <Iconify
              width={50}
              color="#fff"
              icon={videoPlaying ? "solar:pause-bold" : "solar:play-bold"}
            />
          </IconButton>
        )}
        <Stack direction="row" spacing={2} mt={1} justifyContent="center">
          <Button
            variant="outlined"
            startIcon={<Iconify icon="material-symbols:download" />}
            onClick={() => handleDownload(url)}
          >
            Export
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default function ClipsEditorPage() {
  const { user } = useUserAuth();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clips, setClips] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [transcriptWords, setTranscriptWords] = useState([]);
  const [textDisplayMode, setTextDisplayMode] = useState("one_word");
  const [openDialog, setOpenDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectFailed, setProjectFailed] = useState(false);
  const [clipsProject, setClipsProject] = useState([]);
  const { projectId } = useParams();

  const processVideo = (docId) => {
    setProcessing(true);

    // Listen to Firestore document updates
    const projectRef = doc(db, "clips", docId);
    const unsubscribe = onSnapshot(projectRef, (snapshot) => {
      const data = snapshot.data();

      if (data.status === "completed") {
        setClips(data.clips);
        setProcessing(false);
        setTranscript(data.transcript || []);
        setTranscriptWords(data.transcript_words || []);
        unsubscribe(); // Stop listening when done
      } else if (data.status === "failed") {
        setProcessing(false);
        setProjectFailed(true);

        let errMessage;
        switch (data.reason) {
          case "audio-failure":
            errMessage =
              "There was an issue processing audio. Please ensure video has audio";
            break;
          case "file-too-long":
            errMessage = "File is too long. Try 60 minutes or shorter";
            break;
          default:
            errMessage =
              "Oops! We couldn't process the video. Contact us if the issue persists";
        }
        handleShowError(errMessage);
        setClips([]);
        unsubscribe(); // Stop listening on failure
      }
    });

    return unsubscribe; // Return to optionally manage unsubscription
  };

  const handleShowError = (message) => {
    setShowError(true);
    setErrorMessage(message);
  };

  useEffect(() => {
    let unsubscribe;

    const loadProject = async () => {
      try {
        setLoading(true);

        // Fetch initial project data
        const docRef = doc(db, "clips", projectId);
        const querySnapshot = await getDoc(docRef);

        if (querySnapshot.exists()) {
          const projData = querySnapshot.data();

          setClipsProject({
            id: projectId,
            timestamp: projData.timestamp.toDate().toLocaleString(),
            gsUrl: projData.gs_url,
            status: projData.status,
            clips: projData.clips,
          });
          setClips(projData.clips);

          // Start listening for updates
          unsubscribe = processVideo(projectId);
        } else {
          console.error("Document not found");
          handleShowError("Oops, something went wrong");
        }
      } catch (error) {
        console.error("Error loading project:", error);
        handleShowError("Oops, something went wrong");
      } finally {
        setLoading(false);
      }
    };

    loadProject();

    // Cleanup listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Clips Viewer | Acoust AI </title>
      </Helmet>
      <Container>
        <Typography variant="h4">Clips Viewer</Typography>

        {projectFailed && (
          <>
            <Stack
              alignItems="center"
              mt={8}
              minHeight={400}
              sx={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/textspeech-55a09.appspot.com/o/samples%2Fcovers%2Ftaken.svg?alt=media)",
                backgroundSize: "contain", // You can adjust this based on your needs
                backgroundPosition: "bottom", // You can adjust this based on your needs
                backgroundRepeat: "no-repeat",
                height: "60%",
              }}
            ></Stack>
            <Stack mt={2} alignItems="center">
              <Button variant="outlined" component={RouterLink} to="/clips">
                Go Back
              </Button>
            </Stack>
          </>
        )}

        {processing && (
          <Stack spacing={1} mt={4}>
            <LinearProgress></LinearProgress>
            <Typography variant="caption">
              Please wait... This can take 2 - 5 minutes for a 20 minute video.
            </Typography>
            <Typography variant="caption">
              You can leave this page while we process the video. We'll notify
              you when the clips are ready.
            </Typography>
          </Stack>
        )}

        {/* when clips are ready */}
        <Grid container spacing={2} mt={4}>
          {clips?.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <VideoCard url={url} />
            </Grid>
          ))}
        </Grid>
        {/*
          <Stack direction="row">
            <Stack width="50%">
              {transcript.map((subtitle, index) => (
                <div key={index}>
                  <span>
                    {subtitle.start} - {subtitle.end}{" "}
                  </span>
                  <span>{subtitle.word}</span>
                </div>
              ))}
            </Stack>
            <Stack>
              {transcriptWords.map((subtitle, index) => (
                <div key={index}>
                  <span>
                    {subtitle.start} - {subtitle.end}{" "}
                  </span>
                  <span>{subtitle.word}</span>
                </div>
              ))}
            </Stack>
           
          </Stack>*/}

        <ShowMessage
          showFileError={showError}
          setShowFileError={setShowError}
          severity="error"
          message={errorMessage}
        />
      </Container>
    </>
  );
}
