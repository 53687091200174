import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Stack,
  Select,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";

import { alpha, useTheme } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useUserAuth } from "../../UserAuthContext";
import ReactAudioPlayer from "react-audio-player";
import { generateRandomString } from "../../utils/utils";
import mixpanel from "mixpanel-browser";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { analytics, db, logEvent } from "../../firebase";

export default function VoiceSelection(props) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [voices, setVoices] = useState([]);
  const [favoriteVoices, setFavoriteVoices] = useState([]);
  const [showFavorites, setShowFavorites] = useState(false);

  const [cloneVoices, setCloneVoices] = useState("");

  // const [languages, setLanguages] = useState([]);

  const [previewUrl, setPreviewUrl] = useState("");
  const [showEmotions, setShowEmotions] = useState(false);
  const [tempVoice, setTempVoice] = useState("");
  const [applyAll, setapplyAll] = useState(props.useSingleVoice);

  const selectedLanguage = props.selectedLang;
  const setSelectedLanguage = props.setSelectedLanguage;

  // gender filter
  const [selectedGender, setSelectedGender] = useState(""); // Default is all
  const genders = ["Male", "Female"]; // Available genders
  const popularOptions = ["Popular", "All"];
  const [selectedPopularOption, setSelectedPopularOption] = useState("All");

  const [activeTab, setActiveTab] = useState(0);

  const { user } = useUserAuth();

  useEffect(() => {
    async function loadVoices() {
      // console.log("loading voices...", props.selectedVoice);
      if (!selectedLanguage) {
        return;
      }

      // console.log(selectedLanguage);
      setLoading(true);

      let q = query(
        collection(db, "voices"),
        where("languageCodes", "array-contains", selectedLanguage)
      );

      if (selectedGender) {
        q = query(q, where("gender", "==", selectedGender));
      }

      if (props.cloudProvider) {
        q = query(q, where("cloud", "==", props.cloudProvider));
      }

      if (selectedPopularOption === "Popular") {
        q = query(q, where("popular", "==", selectedGender));
      }

      // order by
      q = query(q, orderBy("order"), orderBy("display_name"));
      const querySnapshot = await getDocs(q);

      let defaultVoice;
      if (showFavorites) {
        const tempVoices = querySnapshot.docs
          .map((doc) => ({
            ...doc.data(),
          }))
          .filter((voice) =>
            favoriteVoices.some((favVoice) => favVoice === voice.voice)
          );
        setVoices(tempVoices);
      } else {
        setVoices(querySnapshot.docs.map((doc) => ({ ...doc.data() })));

        // load the 3rd voice where there are enough voices. TODO, maybe try
        // some other logic. This was just a quick test to try different voice
        defaultVoice =
          querySnapshot.docs[querySnapshot.docs.length >= 3 ? 2 : 0]?.data();
      }

      // Set a default voice if there wasn't a voice already selected by user
      // console.log("selected:", props.selectedVoice, props.index);
      if (!props.selectedVoice) {
        // console.log("no default voice");
        props.setSelectedVoice(defaultVoice);
        props.handleVoiceChange(defaultVoice, props.index);
      } // else {
      // props.handleVoiceChange(props.selectedVoice, props.index);
      // }
      setLoading(false);
    }

    async function loadCloneVoices() {
      const q = query(
        collection(db, "clonedvoices"),
        where("user", "==", user.email),
        where("status", "==", "approved")
      );

      const querySnapshot = await getDocs(q);

      setCloneVoices(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          timestamp: doc.data()["timestamp"].toDate().toLocaleDateString(),
        }))
      );
      //console.log(querySnapshot.docs[0].data());
    }

    // Loads user favorite voices
    async function loadFavorites() {
      const userDocRef = doc(db, "customers", user.uid);
      const userDocSnapShot = await getDoc(userDocRef);
      if (userDocSnapShot.exists() && userDocSnapShot.data().favoriteVoices) {
        setFavoriteVoices(userDocSnapShot.data().favoriteVoices);
      }
    }

    loadVoices();
    loadCloneVoices();
    loadFavorites();
    mixpanel.track("Loaded voice selection");
  }, [selectedLanguage, selectedGender, showFavorites]);

  const insertVoice = (voice) => {
    console.log("inserting", voice);
    // console.log(props.mode);
    if (props.mode && props.mode === "editor") {
      if (applyAll) {
        for (let i = 0; i < props.numOfFields; i++) {
          props.handleVoiceChange(voice, i);
        }
      } else {
        // console.log("change");
        props.handleVoiceChange(voice, props.index);
      }
    } else {
      props.handleVoiceChange(voice);
    }

    props.setSelectedVoice(voice);
    mixpanel.track("Selected voice", {
      voice: voice.display_name,
    });
    props.handleCloseVoiceModal();
  };

  const insertVoiceWithEmotion = (voice, emotion) => {
    // set voice first since it resets the emotion
    insertVoice(voice);

    console.log(props.index);
    if (applyAll) {
      for (let i = 0; i < props.numOfFields; i++) {
        props.handleEmotionChange(emotion, i);
      }
    } else {
      props.handleEmotionChange(emotion, props.index);
    }
    props.setSelectedEmotion(emotion);
  };

  const toggleEmotionView = (voice) => {
    if (voice !== tempVoice) {
      setShowEmotions(true);
      setTempVoice(voice);
    } else {
      setShowEmotions(!showEmotions);
    }
  };

  const handleApplyAll = async (event) => {
    setapplyAll(event.target.checked);
    await updateDoc(doc(db, "projects", props.projectId), {
      use_single_voice: event.target.checked,
    });
  };

  const playPreview = async (voice, emotion) => {
    // console.log(voice)
    setLoading(true);
    setTempVoice(voice);

    let url = "";
    if (props.cloudProvider === "gcs") {
      url = voice.preview;
    } else if (voice.preview && selectedLanguage === "en-US") {
      // (TODO) fix this temp hack for preview
      url = voice.preview;
    } else {
      url = await props.generateVoicePreviewAudio(
        voice,
        emotion,
        generateRandomString()
      );
    }
    // console.log(url);

    setPreviewUrl(url);
    setLoading(false);
  };

  const handlePreviewEnded = () => {
    setPreviewUrl(null);
  };

  const handleGenderChange = (gender) => {
    if (gender === selectedGender) {
      setSelectedGender("");
    } else {
      setSelectedGender(gender);
    }
    mixpanel.track("Filter by gender", {
      gender: gender,
    });
  };

  const handleFavoritesButton = () => {
    setShowFavorites(!showFavorites);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // if voice exists in favorites, remove it else add it
  const toggleFavorites = async (voice) => {
    let tempFavoriteVoices = [];
    if (favoriteVoices.some((favoriteVoice) => favoriteVoice === voice.voice)) {
      // console.log("remove");
      tempFavoriteVoices = favoriteVoices.filter(
        (favoriteVoice) => favoriteVoice !== voice.voice
      );
      setFavoriteVoices(tempFavoriteVoices);
    } else {
      // console.log("add");
      mixpanel.track("Favorited voice", {
        voice: voice.display_name,
      });
      tempFavoriteVoices = [...favoriteVoices, voice.voice];
      setFavoriteVoices(tempFavoriteVoices);
    }

    await updateDoc(doc(db, "customers", user.uid), {
      favoriteVoices: tempFavoriteVoices,
    });
  };

  const isFavorite = (voice) => {
    return favoriteVoices.some((favoriteVoice) => favoriteVoice === voice);
  };

  return (
    <>
      <FormControl variant="standard">
        <Dialog
          open={props.openVoiceModal}
          onClose={props.handleCloseVoiceModal}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            sx={{
              backgroundColor: alpha(theme.palette.common.black, 0.5),
            }}
          >
            <Stack justifyContent="space-between" direction="row">
              {props.cloudProvider !== "gcs" && (
                <Stack direction="row" justifyContent="center">
                  <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="AI Voices" />
                    <Tab
                      label={
                        <div>
                          Cloned Voices
                          <Iconify
                            color="#B71C1C"
                            icon="ic:outline-fiber-new"
                          />
                        </div>
                      }
                    />
                  </Tabs>
                </Stack>
              )}
              <IconButton
                size="small"
                variant="text"
                onClick={props.handleCloseVoiceModal}
              >
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent
            sx={{
              height: "800px",
              backgroundColor: alpha(theme.palette.common.black, 0.5),
            }}
          >
            <Box flexGrow={1} mt={4}></Box>

            {/* Language selection */}

            {activeTab === 0 && props.cloudProvider !== "gcs" && (
              <Paper elevation={4}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  spacing={2}
                  padding={2}
                >
                  <Grid item>
                    <FormControl sx={{ minWidth: 150 }} size="small">
                      <InputLabel
                        sx={{ fontSize: "12px" }}
                        id="language-input-label"
                      >
                        Language
                      </InputLabel>
                      <Select
                        sx={{ fontSize: "12px", color: "white" }}
                        labelId="langugage-select-label"
                        id="language-select"
                        label="Language"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                      >
                        <ListSubheader
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.common.black,
                              0.2
                            ),
                            fontSize: "10px",
                          }}
                        >
                          Popular
                        </ListSubheader>
                        {props.languages.map((language, index) => [
                          <MenuItem
                            sx={{ color: "white", fontSize: "12px" }}
                            value={language.code}
                          >
                            {language.language}
                          </MenuItem>,
                          index === 3 && (
                            <ListSubheader
                              key="other-languages"
                              sx={{
                                backgroundColor: alpha(
                                  theme.palette.common.black,
                                  0.2
                                ),
                                fontSize: "10px",
                              }}
                            >
                              Other Languages
                            </ListSubheader>
                          ),
                        ])}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    {/* Gender filter */}
                    <ButtonGroup>
                      {genders.map((gender) => (
                        <Button
                          size="small"
                          key={gender}
                          onClick={() => handleGenderChange(gender)}
                          variant={
                            selectedGender === gender ? "contained" : "outlined"
                          }
                          sx={{ borderRadius: "10px" }}
                        >
                          {gender}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Grid>
                  {/* Favorites filter */}
                  <Grid item>
                    <Button
                      variant={showFavorites ? "contained" : "outlined"}
                      size="small"
                      onClick={() => handleFavoritesButton()}
                      //variant={
                      //  selectedGender === gender ? "contained" : "outlined"
                      //}
                      sx={{ borderRadius: "10px" }}
                    >
                      Favorites
                    </Button>
                  </Grid>
                  {props.mode && (
                    <Grid item>
                      <Stack direction="row" alignItems="center" mr={6}>
                        <Checkbox checked={applyAll} onClick={handleApplyAll} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Apply voice to the entire project
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            )}

            {/* AI Voices */}
            {activeTab === 0 && showFavorites && voices.length === 0 && (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ height: "50vh" }}
              >
                <Typography>
                  🧐 &nbsp; hmm, it looks like we couldn't locate any favorite
                  voices for the selected language ({selectedLanguage})
                </Typography>
              </Stack>
            )}
            {activeTab === 0 && (
              <Grid container spacing={2} mt={1}>
                {voices.map((voice) => (
                  <Grid item xs={12} sm={3} key={voice.voice}>
                    <Paper elevation={4}>
                      <Stack
                        direction="row"
                        spacing={4}
                        padding={2}
                        minHeight={120}
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Avatar
                          alt={voice.gender}
                          src={
                            voice.gender === "Male"
                              ? "/man.png"
                              : voice.gender === "Female"
                              ? "/woman.png"
                              : null
                          }
                          sx={{
                            backgroundColor: (theme) =>
                              alpha(theme.palette.common.white, 0.4),
                          }}
                        >
                          {(voice.gender === "Neutral" ||
                            voice.gender === "Female (Child)") && (
                            <Iconify
                              width="30px"
                              icon="material-symbols:person"
                            />
                          )}
                        </Avatar>

                        <Stack direction="column">
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            {voice.display_name}
                            {voice.isNew && (
                              <Iconify
                                color="#B71C1C"
                                icon="ic:outline-fiber-new"
                              />
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "text.secondary",
                            }}
                          >
                            {voice.gender}
                          </Typography>
                        </Stack>

                        {/* stack for hot & emptions */}
                        <Stack spacing={1}>
                          {voice.emotions && (
                            <Chip
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: "11px",
                                borderRadius: "5px",
                              }}
                              deleteIcon={
                                <Iconify
                                  icon={
                                    showEmotions && voice === tempVoice
                                      ? "mdi:chevron-up"
                                      : "mdi:chevron-down"
                                  }
                                />
                              }
                              label={`${voice.emotions.length} styles`}
                              onDelete={() => toggleEmotionView(voice)}
                            />
                          )}
                          {voice.popular && (
                            <Chip
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: "11px",
                                color: "#E65100",
                                backgroundColor: alpha(
                                  theme.palette.warning.light,
                                  0.08
                                ),
                                borderColor: theme.palette.warning.light,
                                borderRadius: "5px",
                              }}
                              label={"hot 🔥"}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Divider variant="middle" />
                      {/* Preview / Play */}
                      <Stack
                        alignItems="center"
                        padding={1}
                        justifyContent="space-evenly"
                        direction="row"
                      >
                        <Tooltip title="Preview" placement="top" arrow>
                          <LoadingButton
                            loading={tempVoice === voice && loading}
                            size="small"
                            onClick={() => playPreview(voice, "")}
                          >
                            <Iconify icon="octicon:play-16" />
                          </LoadingButton>
                        </Tooltip>
                        <Tooltip title="Favorite" placement="top" arrow>
                          <LoadingButton
                            size="small"
                            onClick={() => toggleFavorites(voice, "")}
                          >
                            {isFavorite(voice.voice) ? (
                              <Iconify icon="material-symbols:favorite" />
                            ) : (
                              <Iconify icon="material-symbols:favorite-outline" />
                            )}
                          </LoadingButton>
                        </Tooltip>
                        <Tooltip title="Use this voice" placement="top" arrow>
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => insertVoice(voice)}
                          >
                            Select
                          </Button>
                        </Tooltip>
                      </Stack>

                      <Stack>
                        {voice === tempVoice &&
                          voice.emotions &&
                          showEmotions && (
                            <Box sx={{ mt: 1, ml: 3 }}>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 120 }}
                              >
                                {voice.emotions.map((emotion) => (
                                  <List key={emotion}>
                                    <ListItem
                                      sx={{ fontSize: "12px" }}
                                      key={emotion}
                                    >
                                      <ListItemIcon>
                                        <Tooltip
                                          title="Preview voice"
                                          placement="top"
                                          arrow
                                        >
                                          <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                              playPreview(voice, emotion)
                                            }
                                          >
                                            <Iconify icon="icon-park-solid:play" />
                                          </IconButton>
                                        </Tooltip>
                                      </ListItemIcon>
                                      <ListItemText>
                                        <Box sx={{ width: "100px" }}>
                                          <Typography sx={{ fontSize: "11px" }}>
                                            {emotion}
                                          </Typography>
                                        </Box>
                                      </ListItemText>
                                      <ListItemIcon>
                                        <Tooltip
                                          title="Use this voice"
                                          placement="top"
                                          arrow
                                        >
                                          <Button
                                            size="small"
                                            onClick={() =>
                                              insertVoiceWithEmotion(
                                                voice,
                                                emotion
                                              )
                                            }
                                          >
                                            Select
                                          </Button>
                                        </Tooltip>
                                      </ListItemIcon>
                                    </ListItem>
                                  </List>
                                ))}
                              </FormControl>
                            </Box>
                          )}
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}

            {/* Tab for cloned voice */}
            {activeTab === 1 && cloneVoices.length > 0 ? (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <Grid item xs={12} md={3}>
                  <List
                    sx={{
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    <Paper elevation={4}>
                      {cloneVoices.map((cloneVoice, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Stack
                                minHeight={100}
                                direction="row"
                                spacing={3}
                                padding={2}
                                alignItems="center"
                              >
                                <Stack direction="column">
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {cloneVoice.display_name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Added: {cloneVoice.timestamp}
                                  </Typography>
                                </Stack>
                              </Stack>
                            }
                          />
                          <ListItemIcon>
                            <Tooltip
                              title="Use this voice"
                              placement="top"
                              arrow
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => insertVoice(cloneVoice)}
                              >
                                Select
                              </Button>
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </Paper>
                  </List>
                </Grid>
              </Grid>
            ) : (
              activeTab === 1 && (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{ height: "70vh" }}
                >
                  <Typography>😿 You haven't cloned any voices yet</Typography>
                  <Button
                    variant="contained"
                    href="/cloning"
                    sx={{ width: "300px" }}
                  >
                    Try now
                  </Button>
                </Stack>
              )
            )}
          </DialogContent>
        </Dialog>
      </FormControl>
      {previewUrl && (
        <ReactAudioPlayer
          onEnded={handlePreviewEnded}
          src={previewUrl}
          preload="none"
          autoPlay
        />
      )}
    </>
  );
}
