import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress, IconButton } from "@mui/material";
import { getVideoElement } from "../../../../Animations/utils";
import { handleAddVideo } from "../../../../canvas/utils/helper";
import { CanvasStore } from "../../../../canvas/store/canvasStore";
import Iconify from "../../../../components/iconify";

import { LinearProgress, Stack } from "@mui/material";

const VideoSlider = ({ videoList, loading }) => {
  const sliderRef = useRef(null);
  const { activeCanvas } = useContext(CanvasStore);
  const [addingVideo, setAddingVideo] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const getHDLink = (videoFiles) => {
    const hdVideo = videoFiles.reduce((max, video) => {
      if (video.quality === "hd") {
        const resolution = video.width * video.height;
        if (!max || resolution > max.width * max.height) {
          return video;
        }
      }
      return max;
    });

    //console.log(hdVideo ? hdVideo.link : videoFiles[0].link);
    return hdVideo ? hdVideo.link : videoFiles[0].link;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
    vertical: false,
  };

  const next = () => {
    sliderRef?.current?.slickNext();
  };

  const previous = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleVideoClick = (item, index) => {
    if (!item) return;

    setAddingVideo(true);
    setSelectedIndex(index);
    // for preloaded stock, just use the url from db
    const url = item.video_files?.length
      ? getHDLink(item.video_files)
      : item.url;
    getVideoElement(url).then((element) => {
      console.log("adding video");
      handleAddVideo(url, item.name, activeCanvas);
      setAddingVideo(false);
    });
  };

  const handlePlayPause = (e) => {
    let video = e.target;
    var isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      var playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    } else {
      video.pause();
    }
  };

  if (loading) {
    return (
      <Stack mt={2}>
        <LinearProgress></LinearProgress>
      </Stack>
    );
  }

  return (
    <div className="mt-4 group">
      <div className="relative">
        <Slider {...settings} ref={sliderRef}>
          {videoList.length > 0 &&
            videoList.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="rounded-lg relative overflow-hidden mr-3"
                    style={{ paddingBottom: "56.25%" }}
                  >
                    <video
                      loop
                      className="absolute inset-0 w-full h-full object-cover rounded-lg"
                      onClick={() => handleVideoClick(item)}
                      onMouseOver={handlePlayPause}
                      onMouseOut={handlePlayPause}
                    >
                      <source
                        // for preloaded stock, just use the url from db
                        src={
                          item.video_files?.length
                            ? getHDLink(item.video_files)
                            : item.url
                        }
                        type="video/mp4"
                      />
                    </video>
                    <div
                      id={`duration-${index}`}
                      className="absolute bottom-1 right-1 p-1 bg-black bg-opacity-50 text-white text-xs rounded flex items-center"
                    >
                      <Iconify width="10" icon="mingcute:time-line" />
                      <span className="ml-1">
                        {`${Math.floor(item.duration / 60)
                          .toString()
                          .padStart(2, "0")}:${Math.floor(item.duration % 60)
                          .toString()
                          .padStart(2, "0")}`}
                      </span>
                    </div>

                    <IconButton
                      className="absolute items-center"
                      onClick={() => handleVideoClick(item, index)}
                    >
                      {addingVideo && index === selectedIndex ? (
                        <CircularProgress />
                      ) : (
                        <Iconify icon="icon-park-solid:add" />
                      )}
                    </IconButton>
                  </div>
                </div>
              );
            })}
        </Slider>
        {/* 
        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={previous}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden right-0  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={next}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
        */}
      </div>
    </div>
  );
};

export default VideoSlider;
