import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { db } from "../firebase";
import {
  arrayUnion,
  collection,
  doc,
  deleteDoc,
  getDocs,
  onSnapshot,
  orderBy,
  where,
  query,
  serverTimestamp,
  addDoc,
  setDoc,
  runTransaction,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../UserAuthContext";
import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import Iconify from "../components/iconify";

import Voices from "./Voices";
import Langs from "./Langs";

import NewVoice from "../components/NewVoice/NewVoice";
import ManageBannerMessage from "../components/ManageBannerMessage/ManageBannerMessage";
import ManageClonedVoices from "../components/ManageClonedVoices/ManageClonedVoices";

// ----------------------------------------------------------------------

export default function JarvisPage() {
  // Helper function -- ignore
  const addVoices = async () => {
    Voices.voices.map(async (item) => {
      console.log(`Adding: ${item.Voice}`);
      await addDoc(collection(db, "voices"), {
        voice: item.Voice,
        code: item.Code,
        gender: item.Gender,
        quality: item.Quality,
        type: item.Type,
        display_name: item.Display,
        language: item.Language,
        order: item.Order,
        preview: item.Preview,
        cloud: item.Cloud,
        //emotions: item.StyleList
      });
    });
  };

  // Helper function to load initial support langs -- ignore
  const addLangs = async () => {
    Langs.langs.map(async (item) => {
      // console.log(`Adding: ${item.Language}`);
      await setDoc(doc(db, "languages", item.Language), {
        language: item.Language,
        order: item.Order,
        code: item.Code,
      });
    });
  };

  async function copyDbtracksToStock() {
    try {
      const dbtracksRef = collection(db, "bgtracks");
      const stockRef = collection(db, "stock");
      const querySnapshot = await getDocs(dbtracksRef);

      querySnapshot.forEach(async (docSnapshot) => {
        const data = docSnapshot.data();

        // Add the 'type' field with value 'tracks'
        const newData = {
          ...data,
          type: "tracks",
        };

        // Write the new document to the 'stock' collection
        await setDoc(doc(stockRef, docSnapshot.id), newData);
      });

      console.log("Copy completed successfully!");
    } catch (error) {
      console.error("Error copying documents: ", error);
    }
  }

  const batchUpdateDocs = async () => {
    try {
      const collectionRef = collection(db, "customers"); // Replace 'your_collection_name' with the actual name of your collection
      const collectionSnapshot = await getDocs(collectionRef); // use without query

      const now = new Date();

      const timestamp = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      const q = query(
        collectionRef,
        where("user", "==", "kamran@nocodr.io"),
        where("timestamp", "<", timestamp)
      );
      /*const collectionSnapshot = await getDocs(q);
      collectionSnapshot.forEach((doc) => {
        deleteDoc(doc.ref)
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      });

      return;*/
      const batchSize = 500; // Maximum batch size allowed by Firestore
      const batches = Math.ceil(collectionSnapshot.size / batchSize);
      for (let i = 0; i < batches; i++) {
        const batch = writeBatch(db);
        const batchDocs = collectionSnapshot.docs.slice(
          i * batchSize,
          i * batchSize + batchSize
        );
        batchDocs.forEach((doc) => {
          const docRef = doc.ref;
          //const docData = doc.data();
          batch.update(docRef, {
            clips_quota: 3600,
            clips_usage: 0,
          });
          // batch.update(docRef, updatedData);
        });

        /*if (docData.quota === 100000 && !docData.name) {
            console.log(docData.email);
            batch.update(docRef, { quota: 10000 });
          }*/
        // batch.update(docRef, { cloud: "gcs" }); // Replace 'newField' with the name of the new field you want to add and 5 with the value you want to set

        await batch.commit();
        console.log("Batch update successful");
      }
    } catch (error) {
      console.error("Error updating documents:", error);
    }
  };

  const [openFilter, setOpenFilter] = useState(false);
  const [surveyRows, setSurveyRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [userRows, setUserRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const { isLoading, user } = useUserAuth();
  const [customer, setCustomer] = useState("");
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customersToday, setCustomersToday] = useState([]);
  const [TTSCount, setTTSCount] = useState([]);
  const [promptCount, setPromptCount] = useState([]);
  const [shorts, setShorts] = useState([]);

  const [cloningVoices, setCloningVoices] = useState([]);

  const [uploads, setUploads] = useState([]);
  const [downloads, setDownloads] = useState([]);

  const [projects, setProjects] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);

  const [reportDays, setReportDays] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchTopUsers = async () => {
      console.log("loading top users");
      const topUsersFunction = httpsCallable(functions, "topcustomers");
      const result = await topUsersFunction();
      setTopUsers(result.data.cdata);
    };

    async function getSurveyAndTopUsers() {
      await fetchTopUsers();
    }

    getSurveyAndTopUsers();
  }, [reportDays]);

  useEffect(() => {
    let timestamp;
    const now = new Date();
    console.log(`report days: ${reportDays}`);
    if (reportDays === 0) {
      now.setHours(0, 0, 0, 0);
    }
    timestamp = new Date(now.getTime() - reportDays * 24 * 60 * 60 * 1000);
    console.log(`timestamp: ${timestamp}`);

    // Fetch customers data from Firestore
    const fetchCustomers = async () => {
      console.log("loading new customers");
      const topUsersFunction = httpsCallable(functions, "topcustomers");
      const result = await topUsersFunction({ timestamp });
      setNewUsers(result.data);
    };

    const fetchTTSCount = async () => {
      const q = query(
        collection(db, "text"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const texts = querySnapshot.docs.map((doc) => doc.data());
      setTTSCount(texts);
    };

    const fetchPromptCount = async () => {
      const q = query(
        collection(db, "prompts"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const prompts = querySnapshot.docs.map((doc) => doc.data());
      setPromptCount(prompts);
    };

    const fetchUploadCount = async () => {
      const q = query(
        collection(db, "uploads"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const uploads = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
          player_url: doc.data().player_url || doc.data().url,
        };
      });
      setUploads(uploads);
    };

    const fetchDownloads = async () => {
      const q = query(
        collection(db, "downloads"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const downloads = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setDownloads(downloads);
    };

    const fetchProjCount = async () => {
      const q = query(
        collection(db, "projects"),
        where("created", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const projs = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
          created: doc.data().created.toDate(),
          fields:
            doc.data().fields && doc.data().fields.length > 0
              ? doc.data().fields[0].text || ""
              : doc.data().text || "",
          type: doc.data().type,
          voice: doc.data().selectedVoice
            ? `${doc.data().selectedVoice.display_name}: ${
                doc.data().selectedVoice.cloud
              }`
            : "",
        };
      });
      setProjects(projs);
    };

    const fetchCloningVoices = async () => {
      const q = query(
        collection(db, "clonedvoices"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setCloningVoices(docs);
    };

    const fetchShorts = async () => {
      const q = query(
        collection(db, "clips"),
        where("timestamp", ">=", timestamp)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setShorts(docs);
    };

    const fetchData = async () => {
      fetchCustomers(); // customers count that took survey
      //fetchTTSCount();
      fetchPromptCount();
      fetchUploadCount();
      fetchDownloads();
      fetchProjCount();
      fetchCloningVoices();
      fetchShorts();
    };
    fetchData();
  }, [reportDays]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (!rows.length) {
      setLoading(false);
      return;
    }

    const temp_rows = [...rows];
    const updateRows = async () => {
      const updatedRows = await Promise.all(
        temp_rows.map(async (row) => {
          let q = query(
            collection(db, "projects"),
            where("user", "==", row.email)
          );
          let querySnapshot = await getDocs(q);
          let count = querySnapshot.size;
          row.projCount = count;

          let countFields = 0;
          let countExport = 0;
          querySnapshot.docs.map((doc) => {
            if (doc.data().export) {
              countExport++;
            }
            if (doc.data().fields) {
              console.log(doc.id);
              console.log(doc.data().fields.length);

              countFields += doc.data().fields.length;
            }
          });

          q = query(collection(db, "text"), where("user_id", "==", row.email));
          querySnapshot = await getDocs(q);
          row.ttsCount = querySnapshot.size;

          row.exportCount = countExport;
          row.fields = countFields;
          console.log(row.email);
          const matchedEntry = topUsers.find(
            (entry) => entry.email === row.email
          );
          row.usage = matchedEntry ? matchedEntry.usage : -1;
          return row;
        })
      );

      return updatedRows;
    };

    updateRows()
      .then((updatedRows) => {
        setUserRows(updatedRows);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [rows, topUsers]);

  let sColumns = [
    {
      field: "usecase",
      width: 200,
      headerName: "Use case",
      editable: false,
    },
    {
      field: "other",
      width: 100,
      headerName: "Other",
      editable: false,
    },
    {
      field: "musthave",
      width: 200,
      headerName: "Must Have",
      editable: false,
    },
    {
      field: "other_must",
      width: 100,
      headerName: "Feature Other",
      editable: false,
    },
    {
      field: "projCount",
      width: 50,
      headerName: "Projs",
      editable: false,
    },
    {
      field: "usage",
      width: 100,
      headerName: "Usage",
      editable: false,
    },
    {
      field: "ttsCount",
      width: 50,
      headerName: "TTS",
      editable: false,
    },
    {
      field: "exportCount",
      width: 50,
      headerName: "Exported",
      editable: false,
    },
    {
      field: "fields",
      width: 50,
      headerName: "Fields",
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 170,
      editable: false,
    },
    {
      field: "timestamp",
      headerName: "Signup",
      width: 150,
      editable: false,
    },
    {
      field: "frequency",
      width: 120,
      headerName: "Frequency",
      editable: false,
    },
  ];

  let projectColumns = [
    {
      field: "user",
      width: 175,
      headerName: "User",
      editable: false,
      renderCell: (params) => (
        <Link
          href={`https://clarity.microsoft.com/projects/view/g6t9mjq29a/impressions?date=Last%203%20days&Variables=user_email%3A${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "id",
      width: 175,
      headerName: "ID",
      editable: false,
      renderCell: (params) => (
        <Link
          href={
            params.row.type === "video"
              ? `https://localhost:3000/v/${params.value}`
              : `https://localhost:3000/editor?id=${params.value}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },

    {
      field: "voice",
      width: 100,
      headerName: "Voice",
      editable: false,
    },
    {
      field: "type",
      width: 60,
      headerName: "Type",
      editable: false,
    },

    {
      field: "fields",
      width: 500,
      headerName: "text",
      editable: false,
    },
    {
      field: "export",
      width: 50,
      headerName: "export",
      editable: false,
    },

    {
      field: "created",
      width: 200,
      headerName: "Date",
      editable: false,
    },
    {
      field: "name",
      width: 50,
      headerName: "Project Name",
      editable: false,
    },
  ];

  let uploadColumns = [
    {
      field: "file_name",
      width: 200,
      headerName: "filename",
      editable: false,
    },
    {
      field: "player_url",
      width: 600,
      headerName: "url",
      editable: false,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      ),
    },

    {
      field: "user",
      width: 200,
      headerName: "user",
      editable: false,
    },
    {
      field: "type",
      width: 200,
      headerName: "type",
      editable: false,
    },
  ];

  let downloadColumns = [
    {
      field: "url",
      width: 600,
      headerName: "url",
      editable: false,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      ),
    },

    {
      field: "user",
      width: 200,
      headerName: "user",
      editable: false,
    },
  ];

  let promptColumns = [
    {
      field: "prompt",
      width: 600,
      headerName: "prompt",
      editable: false,
    },

    {
      field: "user_id",
      width: 200,
      headerName: "user",
      editable: false,
    },
  ];

  let shortsColumns = [
    {
      field: "user_email",
      width: 175,
      headerName: "User",
      editable: false,
      renderCell: (params) => (
        <Link
          href={`https://clarity.microsoft.com/projects/view/g6t9mjq29a/impressions?date=Last%203%20days&Variables=user_email%3A${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "id",
      width: 175,
      headerName: "ID",
      editable: false,
      renderCell: (params) => (
        <Link
          href={`https://localhost:3000/clipseditor/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "video_length",
      width: 200,
      headerName: "length",
      editable: false,
      renderCell: (params) => `${params.row.video_length} seconds`,
    },
  ];

  let CloningColumns = [
    {
      field: "user",
      width: 200,
      headerName: "user",
      editable: false,
    },
    {
      field: "player_url",
      width: 600,
      headerName: "Sample",
      editable: false,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      ),
    },
  ];

  let topuserColumns = [
    {
      field: "email",
      width: 200,
      headerName: "User",
      editable: false,
    },
    {
      field: "status",
      width: 75,
      headerName: "Subscription",
      editable: false,
      renderCell: (params) => {
        const subscription = params.value;

        // Define a custom style based on the subscription value
        const cellStyle = {
          color:
            subscription === "active"
              ? "green"
              : subscription === "prepaid"
              ? "#29B6F6"
              : "inherit",
          fontWeight: subscription === "active" ? "bold" : "normal",
        };
        return <div style={cellStyle}>{subscription}</div>;
      },
    },
    {
      field: "usage",
      width: 100,
      headerName: "Usage",
      editable: false,
    },
    {
      field: "signup",
      width: 100,
      headerName: "Signup",
      editable: false,
    },
    {
      field: "last_login",
      width: 100,
      headerName: "Last Login",
      editable: false,
    },
    {
      field: "reset",
      width: 100,
      headerName: "reset",
      editable: false,
    },
  ];

  const queryColumns = [{ field: "text", width: 1000, headerName: "Text" }];

  const handleReportDaysChange = (event, newDuration) => {
    if (newDuration !== null) {
      console.log(newDuration);
      setReportDays(parseInt(newDuration));
    }
  };

  const countExport = (projects) => {
    let counter = 0;
    projects.map((project) => {
      if (project.export) {
        counter++;
      }
    });
    return counter;
  };

  if (
    user.email !== "kamran@nocodr.io" &&
    user.email !== "adnanmasood1@gmail.com"
  ) {
    return;
  }

  if (loading) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <>
      <Helmet>
        <title> Jarvis | Acoust </title>
      </Helmet>
      <Container>
        <ToggleButtonGroup
          value={reportDays}
          exclusive
          onChange={handleReportDaysChange}
          aria-label="duration toggle"
        >
          <ToggleButton value="0" aria-label="1 day">
            Today
          </ToggleButton>
          <ToggleButton value="1" aria-label="1 day">
            1 Day
          </ToggleButton>
          <ToggleButton value="7" aria-label="7 days">
            7 Days
          </ToggleButton>
          <ToggleButton value="30" aria-label="30 days">
            30 Days
          </ToggleButton>
        </ToggleButtonGroup>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {newUsers.length}
              </Typography>
              <Typography color="textSecondary">Signups</Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {projects.length}: {countExport(projects)}
              </Typography>
              <Typography color="textSecondary">Projects</Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {downloads.length}
              </Typography>
              <Typography color="textSecondary">DLs</Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {promptCount.length}
              </Typography>
              <Typography color="textSecondary">AI</Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {uploads.length}
              </Typography>
              <Typography color="textSecondary">ULs</Typography>
            </CardContent>
          </Card>
        </Stack>

        <Stack mt={4}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Projects" />
            <Tab label="Shorts" />
            <Tab label="Uploads" />
            <Tab label="Downloads" />
            <Tab label="Top Users" />
            <Tab label="Cloning" />
            <Tab label="Admin" />
            <Tab label="New Voice" />
            <Tab label="Manage Banner" />
            <Tab label="Manage C Voices" />
          </Tabs>
        </Stack>

        {activeTab === 0 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              // onRowClick={handleRowClick}
              rows={projects}
              columns={projectColumns}
              sx={{
                fontSize: 12,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          </Box>
        )}

        {/* Shorts */}
        {activeTab === 1 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.timestamp}
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              rows={shorts}
              columns={shortsColumns}
              sx={{
                fontSize: 12,
              }}
            />
          </Box>
        )}

        {/* Uploads */}
        {activeTab === 2 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              rows={uploads}
              columns={uploadColumns}
              sx={{
                fontSize: 12,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "usage", sort: "desc" }],
                },
              }}
            />
          </Box>
        )}

        {/* Downloads through fetch */}
        {activeTab === 3 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              rows={downloads}
              columns={downloadColumns}
              sx={{
                fontSize: 12,
              }}
            />
          </Box>
        )}

        {/* top users */}
        {activeTab === 4 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              // onRowClick={handleRowClick}
              rows={topUsers}
              columns={topuserColumns}
              sx={{
                fontSize: 12,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "usage", sort: "desc" }],
                },
              }}
            />
          </Box>
        )}

        {/* Cloning insights */}
        {activeTab === 5 && (
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.timestamp}
              components={{
                Toolbar: GridToolbar,
              }}
              rowHeight={25}
              rows={cloningVoices}
              columns={CloningColumns}
              sx={{
                fontSize: 12,
              }}
            />
          </Box>
        )}

        {/* common admin tasks */}
        {activeTab === 6 && (
          <Stack mt={5}>
            <Button onClick={addVoices}>Update Voices</Button>
            <Button onClick={addLangs}>Update Langs</Button>
            <Button onClick={batchUpdateDocs}>Batch update collection</Button>
            <Button onClick={copyDbtracksToStock}>Copy tracks</Button>
          </Stack>
        )}

        {/* new voice*/}
        {activeTab === 7 && <NewVoice></NewVoice>}
        {activeTab === 8 && <ManageBannerMessage></ManageBannerMessage>}
        {activeTab === 9 && <ManageClonedVoices />}
      </Container>
    </>
  );
}
