import React, { useContext, useEffect, useState } from "react";
import { fabric } from "fabric";
import _ from "lodash";
import DragableView from "./DragableView";
import { maxTimelineLengthInSeconds } from "./TimelineRuler";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { withModifiedEvent } from "../../canvas/utils/helper";
import { shouldPlayMedia } from "../../Animations/utils";
import Iconify from "../../components/iconify";

function getAnimationProps(object) {
  let obj = _.pick(object, ["startTime", "endTime", "animationStartTime"]);
  obj.duration = obj.endTime - obj.startTime;

  obj.duration /= object.speed || 1;

  return obj;
}

function getLayerColors(type) {
  switch (type) {
    case "text":
    case "i-text":
    case "textbox": {
      return {
        background: "#6453e4",
        border: "#C5CAE9",
        borderHover: "#FFF",
      };
    }
    case "shape": {
      return {
        background: "#ffe069",
        border: "#f0d363",
        borderHover: "#FFF",
      };
    }
    case "video": {
      return {
        background: "#0098fd",
        border: "#0098fd",
        borderHover: "#FFF",
      };
    }
    case "audio": {
      return {
        background: "#006064",
        border: "#00838F",
        borderHover: "#FFF",
      };
    }
    case "image": {
      return {
        background: "#FFC400",
        border: "#f0d363",
        borderHover: "#FFF",
      };
    }
    default: {
      return {
        background: "#FDD835",
        border: "#FFF176",
        borderHover: "#FFF",
      };
    }
  }
}

const TimelineLayer = ({
  currentSeekTime,
  seekUpdateHandler,
  canvas,
  element,
  pixelsPerSecond,
  layersScrollRef,
  onLayerUpdate = () => {},
  dragHandleProps,
}) => {
  const { activeObject } = useContext(CanvasStore);
  const disabled = false;
  const [animationProps, setAnimationProps] = useState(
    getAnimationProps(element)
  );
  const [preview, setPreview] = useState("");
  const [timelineFrames, setTimelineFrames] = useState(element.timelineFrames);
  const [audioWaveForm, setAudioWaveForm] = useState(null);
  const [layerColors, setLayerColors] = useState(getLayerColors(element.type));
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [layerWidth, setLayerWidth] = useState(0);

  useEffect(() => {
    canvas.on("animate:prop:updated", handleAnimateUpdated);
    canvas.on("undo", handleAnimate);
    canvas.on("redo", handleAnimate);
    return () => {
      canvas.off("animate:prop:updated", handleAnimateUpdated);
      canvas.off("undo", handleAnimate);
      canvas.off("redo", handleAnimate);
    };
  }, [canvas, element]);

  function handleAnimate() {
    setAnimationProps(getAnimationProps(element));
  }

  function handleAnimateUpdated(opts) {
    let { target } = opts;
    if (target._id === element._id) {
      setAnimationProps(getAnimationProps(target));
    }
  }

  useEffect(() => {
    setIsSelected(activeObject && activeObject._id === element._id);
  }, [activeObject, element]);

  useEffect(() => {
    setLayerColors(getLayerColors(element.type));
    element.off("audio:buffer:loaded", handleAudioBufferLoaded);
    element.on("audio:buffer:loaded", handleAudioBufferLoaded);
    return () => {
      element.off("audio:buffer:loaded", handleAudioBufferLoaded);
    };
  }, [element]);

  useEffect(() => {
    handleUpdateAudioWaveform();
    setLayerWidth(animationProps.duration * pixelsPerSecond);
  }, [pixelsPerSecond, animationProps]);

  function handleAudioBufferLoaded() {
    setAnimationProps(getAnimationProps(element));
    handleUpdateAudioWaveform();
  }

  const handleUpdateAudioWaveform = _.debounce(() => {
    if (element.type === "audio") {
      element.updateWaveFormCanvas({
        layerWidth: animationProps.duration * pixelsPerSecond,
        ppm: pixelsPerSecond,
      });
      if (element.__waveFormCanvas) {
        setAudioWaveForm(element.__waveFormCanvas.toDataURL());
      }
    }
  }, 200);

  useEffect(() => {
    if (element) {
      if (element.type === "video" && !element.timelineFrames.length) {
        handleGenerateVideoStrips();
      }
    }
  }, [element]);

  const handleGenerateVideoStrips = async () => {
    let width = 100;
    await element.getVideoTimelineFrames({ width: width, height: 55 });
    setTimelineFrames(element.timelineFrames);
  };

  let handleLeftDragComplete = (value) => {
    withModifiedEvent(
      element,
      canvas,
      () => {
        let animationStartTime = element.animationStartTime;
        element.set({ animationStartTime: value });

        let startTime = element.startTime + (value - animationStartTime);

        if (startTime < 0) startTime = 0;

        element.startTime = startTime;
        setAnimationProps(getAnimationProps(element));
        onLayerUpdate(element);
      },
      ["animationStartTime", "startTime"],
      "animationPropChanged"
    )();
  };

  let handleDragLayerComplete = (value) => {
    withModifiedEvent(
      element,
      canvas,
      () => {
        element.set({
          animationStartTime: value,
        });

        setAnimationProps(getAnimationProps(element));
        onLayerUpdate(element);
        element.visible = shouldPlayMedia(element, currentSeekTime);
        canvas.requestRenderAll();
        // seekUpdateHandler()
      },
      ["animationStartTime"],
      "animationPropChanged"
    )();
  };

  let handleDragRightComplete = (value) => {
    withModifiedEvent(
      element,
      canvas,
      () => {
        element.set({ endTime: value });
        setAnimationProps(getAnimationProps(element));
        onLayerUpdate(element);
      },
      ["endTime"],
      "animationPropChanged"
    )();
  };

  let left = -element.startTime * pixelsPerSecond;
  let doubleWidthPerPixel = pixelsPerSecond * 4;

  return (
    <div className="flex items-center">
      <div
        onClick={() => {
          canvas.setActiveObject(element);
          canvas.requestRenderAll();
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          width: layerWidth,
          height: element.type === "video" ? 55 : 25,
          left: animationProps.animationStartTime * pixelsPerSecond,
          border: `solid 2px ${
            isHovered || isSelected
              ? layerColors.borderHover
              : layerColors.border
          }`,
        }}
        key={element.id}
        className={`relative overflow-hidden border rounded rounded-[8px]  my-2 flex items-center `}
      >
        {/*left handle*/}
        <DragableView
          className="z-10 flex items-center cursor-col-resize"
          value={animationProps.animationStartTime}
          disabled={disabled}
          pixelsPerSecond={pixelsPerSecond}
          layersScrollRef={layersScrollRef}
          min={0}
          onChange={handleLeftDragComplete}
        >
          <DragHandleIcon
            style={{
              transform: "rotate(90deg)",
              display: isSelected ? "" : "none",
            }}
            sx={{ color: layerColors.borderHover }}
            fontSize="small"
          />
        </DragableView>
        {/*main handle */}
        <DragableView
          className={`  ${disabled ? "cursor-no-drop" : "cursor-pointer"}`}
          value={animationProps.animationStartTime}
          disabled={disabled}
          pixelsPerSecond={pixelsPerSecond}
          layersScrollRef={layersScrollRef}
          min={0}
          max={maxTimelineLengthInSeconds - animationProps.duration}
          style={{ width: layerWidth }}
          onChange={handleDragLayerComplete}
        >
          <div
            style={{ backgroundColor: layerColors.background, color: "white" }}
            className={` title flex h-full w-full text-white text-xs min-w-[0px] px-2 leading-[25px] items-center `}
          >
            {audioWaveForm && (
              <img src={audioWaveForm} className={"w-full h-full"} alt="" />
            )}

            <span className={"ml-4"} style={{ color: layerColors.borderHover }}>
              {element.text || element.type}
            </span>
          </div>
          {timelineFrames && (
            <div
              style={{ left: left }}
              className={"flex h-full  top-0 right-0 absolute"}
            >
              {timelineFrames.map((frameData, index) => {
                return (
                  <div
                    key={index}
                    className={"flex-shrink-0"}
                    style={{
                      width: doubleWidthPerPixel,
                      background: `url(${frameData}) no-repeat center center`,
                    }}
                  ></div>
                );
              })}
            </div>
          )}
        </DragableView>
        {/*right handle*/}
        <DragableView
          className="z-10 flex items-center cursor-col-resize"
          disabled={disabled}
          pixelsPerSecond={pixelsPerSecond}
          value={animationProps.endTime}
          min={0}
          layersScrollRef={layersScrollRef}
          max={
            element.type === "video"
              ? element.animationStartTime + element.duration
              : fabric.MAX_SAFE_INTEGER
          }
          style={{ right: 0 }}
          onChange={handleDragRightComplete}
        >
          <DragHandleIcon
            style={{
              transform: "rotate(90deg)",
              display: isSelected ? "" : "none",
            }}
            sx={{ color: layerColors.borderHover }}
            fontSize="small"
          />
        </DragableView>
      </div>
      <div
        style={{
          display: isSelected ? "" : "none",
          paddingTop: "6px",
          position: "absolute",
          left:
            animationProps.animationStartTime * pixelsPerSecond +
            animationProps.endTime * pixelsPerSecond +
            10,
        }}
        {...dragHandleProps}
      >
        <Iconify icon="mdi:drag" />
      </div>
    </div>
  );
};
export default TimelineLayer;
