// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Projects",
    path: "/home",
    icon: icon("home"),
  },
  {
    title: "Clips",
    path: "/clips",
    icon: icon("clips"),
  },
  {
    title: "Voice Cloning",
    path: "/cloning",
    icon: icon("sound-wave"),
  },
  {
    title: "Templates",
    path: "/templates",
    icon: icon("local-library-rounded"),
  },
  {
    title: "Billing & Support",
    path: "/billing",
    icon: icon("ic_settings-solid"),
  },
  {
    title: "Teams",
    path: "/team",
    icon: icon("team"),
  },
];

export default navConfig;
