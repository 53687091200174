import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from "@mui/material";
import { getVideoElement } from "../../../../Animations/utils";
import { fabric } from "fabric";
import { CanvasStore } from "../../../../canvas/store/canvasStore";
import Iconify from "../../../../components/iconify";
import {
  handleAddImage,
  handleAddVideo,
} from "../../../../canvas/utils/helper";

import { LinearProgress, Stack } from "@mui/material";

const VideoSlider = ({ videoList, loading, mediaType }) => {
  const sliderRef = useRef(null);
  const { activeCanvas } = useContext(CanvasStore);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
    vertical: false,
  };

  const next = () => {
    sliderRef?.current?.slickNext();
  };

  const previous = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleVideoClick = (item) => {
    console.log("slider");
    if (!item) return;
    const url = item.url;
    getVideoElement(url).then((element) => {
      handleAddVideo(url, item.name, activeCanvas);
    });
  };

  const handleImageClick = (item) => {
    handleAddImage(item.url, item.name, activeCanvas);
  };

  const handlePlayPause = (e) => {
    let video = e.target;
    var isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      var playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    } else {
      video.pause();
    }
  };

  if (loading) {
    return (
      <Stack mt={2}>
        <LinearProgress></LinearProgress>
      </Stack>
    );
  }

  if (mediaType === "video") {
    return (
      <div className="mt-4 group">
        <div className="relative">
          <Slider {...settings} ref={sliderRef}>
            {videoList.length > 0 &&
              videoList.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="rounded-lg relative overflow-hidden mr-3"
                      style={{ paddingBottom: "56.25%" }}
                    >
                      <video
                        loop
                        muted
                        className="absolute inset-0 w-full h-full object-cover rounded-lg"
                        onClick={() => handleVideoClick(item)}
                        onMouseOver={handlePlayPause}
                        onMouseOut={handlePlayPause}
                      >
                        <source src={item.url} type="video/mp4" />
                      </video>

                      <IconButton
                        className="absolute items-center"
                        onClick={() => handleVideoClick(item)}
                      >
                        <Iconify icon="icon-park-solid:add" />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
          </Slider>
          {/* 
        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={previous}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden right-0  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={next}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
        */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-4 group">
        <div className="relative">
          <Slider {...settings} ref={sliderRef}>
            {videoList.length > 0 &&
              videoList.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="rounded-lg relative overflow-hidden mr-3">
                      <img
                        width={200}
                        src={item.url}
                        alt=""
                        onClick={() => handleImageClick(item)}
                      ></img>

                      <IconButton
                        className="absolute top-0 left-0 m-2"
                        onClick={() => handleImageClick(item)}
                      >
                        <Iconify icon="icon-park-solid:add" />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
          </Slider>
          {/* 
        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={previous}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <IconButton
          sx={{ transform: "translateY(-50%)" }}
          className="group-hover:block hidden right-0  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={next}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
        */}
        </div>
      </div>
    );
  }
};

export default VideoSlider;
